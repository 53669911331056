import React from "react"
import PropTypes from "prop-types"

export default function Email ({ color = APP_CONFIG.theme.colors.grey.g800, size = 100, ...props }) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 32 32"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="0.7"
      fill="none"
      height={size}
      width={size}
      {...props}
    >
      <path d="M30,23.4c0,0.5-0.4,0.9-0.9,0.9H2.9c-0.5,0-0.9-0.4-0.9-0.9V8.6c0-0.5,0.4-0.9,0.9-0.9h26.2c0.5,0,0.9,0.4,0.9,0.9V23.4z" />
      <polyline points="2,8.2 16,16 30,8.2" />
    </svg>
  )
}

Email.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
