import React from "react"
import BlockTitle from "./blockTitle.component"
import PropTypes from "prop-types"
import Section from "../section.component"

export default function Block ({
  Component,
  children,
  blockTitleStyle,
  blockTitle,
  blockTitleSeo,
  blockTitleColor,
  blockTitleBgColor,
  blockAnchor = ``,
  blockBgColor,
  blockBgWidth,
  blockContentWidth,
  ...componentProps
}) {
  const onlyTitle = !Component && !children
  const BlockComponent = Component || React.Fragment

  return (
    <Section
      data-testid={arguments[0][`data-testid`] || undefined}
      id={blockAnchor || undefined}
      bgColor={blockBgColor}
      bgWidth={blockBgWidth}
      contentWidth={blockContentWidth}
      padding
    >
      {blockTitle && (
        <BlockTitle
          data-testid="block_title"
          style={blockTitleStyle}
          seo={blockTitleSeo}
          color={blockTitleColor}
          bgColor={blockTitleBgColor}
          noMargin={onlyTitle}
        >
          {blockTitle}
        </BlockTitle>
      )}
      <BlockComponent {...componentProps}>{children}</BlockComponent>
    </Section>
  )
}

Block.propTypes = {
  Component: PropTypes.elementType,
  children: PropTypes.any,
  blockTitleStyle: PropTypes.any,
  blockTitle: PropTypes.any,
  blockTitleSeo: PropTypes.any,
  blockTitleColor: PropTypes.any,
  blockTitleBgColor: PropTypes.any,
  blockAnchor: PropTypes.string,
  blockBgColor: PropTypes.any,
  blockBgWidth: PropTypes.any,
  blockContentWidth: PropTypes.any,
}
