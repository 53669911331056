export function omit (obj, ...properties) {
  const newObj = { ...obj }

  for (const property of properties) {
    delete newObj[property]
  }

  return newObj
}

export function flatMap (key) {
  return (accumulator, item) => {
    if (Array.isArray(item[key])) {
      accumulator.push(...item[key])
    }

    return accumulator
  }
}

export function uniqBy (key) {
  return (accumulator, item) => {
    if (!accumulator.find(accItem => accItem[key] === item[key])) {
      accumulator.push(item)
    }

    return accumulator
  }
}

export function sortStringBy (key, order = `asc`) {
  const orderFactor = order === `asc` ? 1 : -1

  return (a, b) => orderFactor * a.name.localeCompare(b.name)
}
