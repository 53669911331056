import React, { useState } from "react"
import styled from "styled-components"
import Email from "../../components/pictos/email.component"
import Phone from "../../components/pictos/phone.component"
import { telMask } from "../../utils/mask"
import { postContact } from "./contact.api"
import { sentryCaptureException } from "../thirdParties/sentry"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import { compose, email, mini, noNumber, required, tel, text } from "../../utils/validate"
import { colors } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"

import Form from "../../components/form/form.component"
import Field from "../../components/form/field.component"
import { useDraft } from "../../components/form/plugins/draft.component"
import InputText from "../../components/form/inputText.component"
import TextArea from "../../components/form/textarea.component"
import SubmitButton from "../../components/form/submitButton.component"
import { useCaptcha } from "../../components/form/plugins/captcha.component"

const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Error = styled.div`
  color: ${colors(`primary`)};
`

const Line = styled.div`
  width: 100%;
`

const Name = styled.div`
  width: 100%;

  ${breakpoint(`medium`)`
    width: 50%;
    padding: 0 0 0 15px;

    &:first-child {
    padding: 0 15px 0 0;
    }
  `};
`

const Submit = styled(SubmitButton)`
  margin: 0 auto;
`

const formInitialState = {
  firstName: ``,
  lastName: ``,
  email: ``,
  phone: ``,
  message: ``,
}

const validate = values => ({
  firstName: compose(required, text, mini(2), noNumber)(values.firstName),
  lastName: compose(required, text, mini(2), noNumber)(values.lastName),
  email: compose(required, email, mini(2))(values.email),
  phone: compose(required, tel)(values.phone),
  message: compose(required, mini(15))(values.message),
})

export default function ContactForm () {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const [inError, setInError] = useState(false)

  const captcha = useCaptcha()
  const draft = useDraft(`contact`)
  const onSubmit = ({ captchaToken, ...values }) => postContact(values, captchaToken)
  const onSubmitSuccess = () => navigate(`contactConfirmation`)
  const onSubmitFail = (values, actions, error) => {
    setInError(true)
    sentryCaptureException(error)
  }

  return (
    <Form
      name="contact"
      initialValues={formInitialState}
      validate={validate}
      captcha={captcha}
      draft={draft}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
    >
      <FormWrapper>
        <Name>
          <Field
            component={InputText}
            type="text"
            name="firstName"
            label={t(`common:generic.form_field_first_name`) + ` *`}
            placeholder={t(`common:generic.form_field_first_name`)}
          />
        </Name>
        <Name>
          <Field
            component={InputText}
            type="text"
            name="lastName"
            label={t(`common:generic.form_field_last_name`) + ` *`}
            placeholder={t(`common:generic.form_field_last_name`)}
          />
        </Name>
        <Line>
          <Field
            component={InputText}
            name="email"
            label={t(`common:contact.page_form_field_your_email`) + ` * :`}
            placeholder={t(`common:contact.page_form_field_your_email`)}
            Picto={Email}
            pictoSize={35}
          />
        </Line>
        <Line>
          <Field
            component={InputText}
            name="phone"
            label={t(`common:contact.page_form_field_your_phone`) + ` * :`}
            placeholder={t(`common:contact.page_form_field_your_phone`)}
            Picto={Phone}
            mask={telMask}
          />
        </Line>
        <Line>
          <Field
            component={TextArea}
            name="message"
            label={t(`common:contact.page_form_field_message`) + ` *`}
            placeholder={t(`common:contact.page_form_field_message`)}
          />
        </Line>

        {inError && (
          <Line>
            <Error data-testid="contact_form_error">{t(`common:generic.form_error`)}</Error>
          </Line>
        )}

        <Submit data-testid="contact_form_submit">{t(`common:contact.page_form_submit`)}</Submit>
      </FormWrapper>
    </Form>
  )
}
