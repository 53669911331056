import React from "react"
import PropTypes from "prop-types"
import { useFormikContext } from "formik"
import InfoAndError from "./infoAndError.component"
import Label from "./label.component"
import styled from "styled-components"
import { colors } from "../../modules/structure/theme"
import { StyledField } from "./styledField.component"
import { Helper } from "./helper.component"
import Notice from "./notice.component"

export const Textarea = styled.textarea`
  resize: vertical;
  display: block;
  width: 100%;
  min-height: 150px;
  background-color: ${colors(`grey.g300`)};
  color: ${colors(`grey.g800`)};
  padding: 12px;
  line-height: 1.5;
  outline: none;
  border-bottom-style: solid;
  border-width: 0 0 3px;
  border-bottom-color: ${props => {
    if (props.isTouched) {
      if (props.hasError) {
        return colors(`error`)
      }

      return colors(`valid`)
    }

    return `transparent`
  }};

  &:focus {
    outline: none;
  }
`

export default function TextArea ({ id, placeholder, label = ``, help = ``, notice = ``, renderInfo, field, innerRef }) {
  const { handleChange, handleBlur, errors, touched } = useFormikContext()
  const { name, value } = field

  return (
    <StyledField flex>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Helper help={help} />
      <Notice text={notice} />
      <Textarea
        id={id}
        placeholder={placeholder}
        data-testid={`form_field_${name}`}
        name={name}
        value={value}
        ref={innerRef}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={errors[name]}
        isTouched={touched[name]}
      />
      <InfoAndError field={field} renderInfo={renderInfo} />
    </StyledField>
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  notice: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  renderInfo: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  innerRef: PropTypes.any,
}
