import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
import { BigTitle, MediumTitle, SmallTitle } from "../titles.component"

const TITLES = {
  big: BigTitle,
  medium: MediumTitle,
  small: SmallTitle,
  fancy: loadable(() => import(`./blockFancyTitle.component`)),
}

export default function BlockTitle ({
  children,
  seo = ``,
  style = `medium`,
  color = ``,
  bgColor = ``,
  noMargin = false,
}) {
  const Component = TITLES[style]

  if (!Component) {
    if (!process.env.OPTIMIZED) {
      throw new Error(`Block title not found: "${style}"`)
    }

    return null
  }

  return (
    <Component
      data-testid={arguments[0][`data-testid`] || undefined}
      seo={seo}
      $color={color}
      bgColor={bgColor}
      noMargin={noMargin}
    >
      {children}
    </Component>
  )
}

BlockTitle.propTypes = {
  children: PropTypes.any,
  seo: PropTypes.oneOf([`h1`, `h2`, `h3`, `h4`, `h5`]),
  style: PropTypes.oneOf([`big`, `medium`, `small`, `fancy`]),
  color: PropTypes.string,
  bgColor: PropTypes.string,
  noMargin: PropTypes.bool,
}
