import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { Button } from "../../modules/structure/theme/button.component"
import { useFormikContext } from "formik"

const SubmitButton = forwardRef(({ children, ...props }, ref) => {
  const { setTouched, initialValues } = useFormikContext()

  return (
    <Button
      type="submit"
      {...props}
      ref={ref}
      onMouseDown={e => {
        e.preventDefault()

        const tempValues = { ...initialValues }
        Object.keys(tempValues).forEach(key => {
          tempValues[key] = true
        })

        setTouched(tempValues)
        e.target.focus()
      }}
    >
      {children}
    </Button>
  )
})

SubmitButton.propTypes = {
  children: PropTypes.any.isRequired,
}

export default SubmitButton
