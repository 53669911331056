import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import loadable from "@loadable/component"
import { ModalButton } from "../../components/modal/modal.component"
import { colors, fontSizes } from "../structure/theme"
import { Bold } from "../structure/theme/styles.component"
import { useViewPageTracking } from "../thirdParties/gtm/tagManager"
import ContactForm from "./contactForm.component"
import WebCallbackButton from "./webCallback/webCallBackButton.component"
import { firstLetterUppercase } from "../../utils/format"
import { ButtonLink } from "../structure/theme/button.component"
import { useTranslation } from "../translation/translate.component"
import ContactLegal from "./contactLegal.component"
import { useWebsiteConfiguration } from "../website/useWebsiteConfiguration.hook"
import Block from "../../components/block/block.component"

const WebCallbackModal = loadable(() => import(`./webCallback/webCallbackModal.component`), { ssr: false })

const Conditions = styled.div`
  color: ${colors(`grey.g600`)};
  font-size: ${fontSizes(`x_small`)};
  text-align: justify;
`

const Line = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${breakpoint(`small`)`
    flex-direction: row;
  `};
`

const BlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${breakpoint(`medium`)`
    justify-content: left;
  `};
`

const contactButtonStyle = css`
  width: 100%;
  margin: 10px;

  ${breakpoint(`small`)`
    margin-right: 10px;
    width: auto;
  `};
`

const PhoneButtonAnchor = styled(ButtonLink)`
  ${contactButtonStyle};
`

const ContactWebCallbackButton = styled(WebCallbackButton)`
  ${contactButtonStyle};
`

function ContactBlock ({ children, phoneNumber, costMention, schedules, partnerCode }) {
  const { t } = useTranslation()

  return (
    <BlockWrapper>
      <Bold>{children}</Bold>
      <Line>
        <PhoneButtonAnchor href={`tel:${phoneNumber}`} data-testid={`contact_${arguments[0][`data-testid`]}_phone`}>
          {phoneNumber}
        </PhoneButtonAnchor>
        {APP_CONFIG.featureFlags.webCallback && (
          <ModalButton modalComponent={WebCallbackModal} modalProps={{ partnerCode, schedules }}>
            {onClick => (
              <ContactWebCallbackButton onClick={onClick} data-testid={`contact_${arguments[0][`data-testid`]}_wcb`}>
                {t(`common:webcallback.form_submit`)}
              </ContactWebCallbackButton>
            )}
          </ModalButton>
        )}
      </Line>
      <i data-testid={`contact_${arguments[0][`data-testid`]}_schedules`}>{firstLetterUppercase(schedules)}</i>
    </BlockWrapper>
  )
}

ContactBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  phoneNumber: PropTypes.string.isRequired,
  costMention: PropTypes.bool.isRequired,
  schedules: PropTypes.string.isRequired,
  partnerCode: PropTypes.string.isRequired,
}

export default function ContactPage () {
  const { t } = useTranslation()
  const { salesPhone } = useWebsiteConfiguration()

  useViewPageTracking(`Contacts`, ``, `Formulaire`)

  return (
    <>
      <Block
        blockTitleStyle="big"
        blockTitleSeo="h1"
        blockTitle={
          <>
            <Bold>{t(`common:contact.page_title_1`)}</Bold>
            <br />
            {t(`common:contact.page_title_2`)}
          </>
        }
      />

      <Block blockTitle={t(`common:contact.page_section_client`)}>
        <ContactBlock
          data-testid="assistance"
          phoneNumber={APP_CONFIG.assistanceNumber}
          costMention={false}
          schedules={t(APP_CONFIG.schedules.assistance)}
          partnerCode={APP_CONFIG.webcallbackPartnerCodes.assistance}
        >
          {t(`common:contact.page_section_client_assistance`)} :
        </ContactBlock>

        <ContactBlock
          data-testid="service"
          phoneNumber={APP_CONFIG.serviceNumber}
          costMention={false}
          schedules={t(APP_CONFIG.schedules.service)}
          partnerCode={APP_CONFIG.webcallbackPartnerCodes.service}
        >
          {t(`common:contact.page_section_client_service`)} :
        </ContactBlock>
      </Block>

      <Block blockTitle={t(`common:contact.page_section_prospect`)}>
        <ContactBlock
          data-testid="sales"
          phoneNumber={salesPhone}
          costMention
          schedules={t(APP_CONFIG.schedules.sales)}
          partnerCode={t(APP_CONFIG.webcallbackPartnerCodes.sales)}
        >
          {t(`common:contact.page_section_prospect_sales`)} :
        </ContactBlock>
      </Block>

      <Block blockTitle={t(`common:contact.page_form_title`)}>
        <ContactForm />
        <Conditions data-testid="data_notice">
          <p>* {t(`common:generic.form_required_mention`)}</p>
          <p>
            <ContactLegal />
          </p>
        </Conditions>
      </Block>
    </>
  )
}

ContactPage.propTypes = {}
