import React from "react"
import loadable from "@loadable/component"
import { useTranslation } from "../../modules/translation/translate.component"

const ReactModal = loadable(() => import(`@homeserve/react-modal`), {
  ssr: false,
  resolveComponent: module => module.Modal,
})

const ReactModalButton = loadable(() => import(`@homeserve/react-modal`), {
  ssr: true,
  resolveComponent: module => module.ModalButton,
})

function useCloseText () {
  const { t } = useTranslation()

  return t(`common:generic.modal_close_button`)
}

export function ModalButton (props) {
  const closeText = useCloseText()

  return <ReactModalButton closeText={closeText} {...props} />
}

export default function Modal (props) {
  const closeText = useCloseText()

  return <ReactModal closeText={closeText} {...props} />
}
