import styled, { css } from "styled-components"
import { fontSizes } from "../modules/structure/theme"
import breakpoint from "styled-components-breakpoint"
import { isValidElement } from "react"

const titleStyle = css`
  text-align: center;
  width: 100%;
  word-break: break-word;
  font-weight: 900;
  margin: 0 0 ${props => !props.noMargin && `24px`};
  color: ${props => props.$color || props.theme.colors.primary};

  > p {
    margin: 0;
  }
`

const defaultProps = ({ children, seo, noMargin, $color }) => {
  const isChildrenNode = isValidElement(children)

  return {
    as: seo || `div`,
    noMargin,
    $color,
    children: isChildrenNode ? children : undefined,
    dangerouslySetInnerHTML: isChildrenNode ? undefined : { __html: children },
  }
}

export const BigTitle = styled.h1.attrs(defaultProps)`
  ${titleStyle};
  font-size: ${fontSizes(`xx_large`)};

  ${breakpoint(`medium`)`
    font-size: ${fontSizes(`xxx_large`)};
    margin: 0 0 32px;
  `}
`

export const MediumTitle = styled.h2.attrs(defaultProps)`
  ${titleStyle};
  font-size: ${fontSizes(`x_large`)};
`

export const SmallTitle = styled.h3.attrs(defaultProps)`
  ${titleStyle};
  color: ${props => props.$color || props.theme.colors.grey.g800};
  font-size: ${fontSizes(`small`)};
`
