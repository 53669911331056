import { useEffect, useMemo, useRef } from "react"
import { useFormikContext } from "formik"
import { useSessionStorage } from "@homeserve/react-storage-provider"
import { omit } from "../../../utils/collection"
import { debounce } from "@homeserve/react-hooks"

export function useDraft (storageKey) {
  const { read, write, isReady } = useSessionStorage()
  const noMoreUpdate = useRef(false)

  const [deleteDraft, updateDraft] = useMemo(() => {
    const _deleteDraft = (_noMoreUpdate = false) => {
      if (read(`draft`)) {
        write(`draft`, omit(read(`draft`), storageKey))
        noMoreUpdate.current = _noMoreUpdate
      }
    }

    const _updateDraft = values => {
      // Be sure debounced updates won't restore previous deleted draft
      if (noMoreUpdate.current) {
        return
      }

      if (!values || Object.keys(values).length === 0) {
        deleteDraft()
      } else {
        write(`draft`, {
          ...read(`draft`),
          [storageKey]: values,
        })
      }
    }

    return [_deleteDraft, _updateDraft]
  }, [read, write])

  return useMemo(() => {
    return {
      name: `draft`,
      Component: Draft,
      props: { updateDraft },
      isReady,
      initialValues: read(`draft`)?.[storageKey] ?? null,
      onSubmitSuccess () {
        deleteDraft(true)
      },
    }
  }, [isReady, read, storageKey, deleteDraft, updateDraft])
}

function Draft ({ updateDraft }) {
  const { values, errors } = useFormikContext()
  const updateWrapper = useRef()

  useEffect(() => {
    updateWrapper.current = debounce(updateDraft, APP_CONFIG.draftDebounce)
  }, [updateDraft])

  useEffect(() => {
    const newValues = { ...values }

    for (const field in newValues) {
      const value = newValues[field]

      if (errors[field] || value === `` || value === null || value === undefined) {
        delete newValues[field]
      }
    }

    updateWrapper.current(newValues)
  }, [values, errors])

  return null
}
