import React from "react"
import Link from "../navigation/link.component"

export default function ContactLegal () {
  return (
    <>
      Les informations recueillies permettront à HomeServe de vous recontacter. Vous bénéficiez d’un droit d’accès, de
      rectification, de limitation, d'effacement et d’opposition à l’utilisation de vos données personnelles. Vous
      pouvez, à tout moment, changer vos préférences de contact sur votre espace client. Pour plus d’informations,
      consultez notre{` `}
      <Link
        target="_blank"
        rel="noreferrer"
        href="https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=Footer"
        data-testid="contact_legal_link"
      >
        Politique de données personnelles
      </Link>
      .
    </>
  )
}
